<template>
  <div v-if="data?.PostVideo" class="video-card" @click="onViewVideo(data?.PostKey)">
    <div v-if="data?.TotalView && data?.TotalView > 0" class="counter">
      <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
      {{ $h.formatDisplayNumber(data?.TotalView) }}
    </div>

    <video controls muted :poster="data?.PostImage ? $h.getImage(data?.PostImage, 'POST') : ''">
      <source :src="$h.getVideo(data?.PostVideo, 'POST')" type="video/mp4" />
      {{ $t.getTranslation("LBL_BROWSER_NO_SUPPORT_VIDEO") }}
    </video>

    <div v-if="data?.PostKey" class="content">
      <div v-if="!hideProfile" class="profile" @click.stop="onViewInfluencer(data?.UserKey)">
        <img loading="lazy" :src="$h.getImage(data?.UserImage, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
        <h3>{{ data?.UserName }}</h3>

        <div class="options">
          <f7-link @click.stop="likeToggle(data?.PostKey)">
            <font-awesome-icon v-if="data?.IsLike === 1" :icon="['fas', 'heart']" fixed-width class="fill" />
            <font-awesome-icon v-else :icon="['far', 'heart']" fixed-width />

            <span v-if="data?.TotalLike > 0">{{ $h.formatDisplayNumber(data?.TotalLike) }}</span>
          </f7-link>
        </div>
      </div>

      <p v-if="data?.PostTitle">{{ $h.formatTrimString(data?.PostTitle, 80) }}</p>

      <p v-if="data?.PostText">{{ $h.formatTrimString(data?.PostText, 80) }}</p>

      <template v-if="showProducts && data?.ProductList && data?.ProductList.length > 0">
        <f7-swiper :slides-per-view="'auto'" :space-between="10">
          <f7-swiper-slide v-for="(product, ind) in data?.ProductList" :key="'pr_' + ind">
            <ProductListCardComponent :data="product" type="select" @click.prevent.stop @selected="selectProduct" />
          </f7-swiper-slide>
        </f7-swiper>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, toRefs, defineAsyncComponent } from "vue";
import { post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";

import { f7 } from "framework7-vue";

// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";

const ProductListCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list-card" */ /* webpackMode: "lazy" */ "@/components/cards/ProductListCardComponent.vue"));

export default defineComponent({
  name: "VideoCardComponent",
  components: {
    ProductListCardComponent,
  },
  props: {
    data: Object,
    hideProfile: {
      type: Boolean,
      default: false,
    },
    showProducts: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();

    const $f7router = f7.views.get("#main-view").router;

    const { data } = toRefs(props);

    const onViewVideo = (key) => {
      $f7router.navigate({ name: "videoViewPage", params: { key } });
    };

    const likeToggle = async (PostKey) => {
      if (!helpers.isUserLogin()) {
        $f7router.navigate({ name: "loginPage" });
        return;
      }

      await post("/post/like/toggle", { PostKey: PostKey });

      if (data.value.IsLike === 1) {
        data.value.IsLike = 0;
        data.value.TotalLike--;
      } else {
        data.value.IsLike = 1;
        data.value.TotalLike++;
      }
    };

    const onViewInfluencer = (userKey) => {
      $f7router.navigate({ name: "influencerViewPage", params: { code: userKey } });
    };

    const selectProduct = (data) => {
      store.dispatch("view/increase", { type: "POSTPRODUCT", key: data.PostProductId });
      $f7router.navigate({ name: "productViewPage", params: { code: data.ProductKey } });
    };

    return { onViewVideo, likeToggle, onViewInfluencer, selectProduct };
  },
});
</script>
